import React, {useState} from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect, useDispatch, useSelector} from 'react-redux'

import BagImage from '../../../assets/product_card_bag.svg'
import {removeProductFromBag} from '../../../infra/utils/BagActions'
import GetImage from '../../../shared/components/Image'
import ComponentLoadingOverlay from '../../../shared/components/loading/ComponentLoadingOverlay'
import {
  CategoryTag,
  CreditsTag,
  CreditsWrapper,
  ImageBag,
  ImageLink,
  ImageP,
  ImagePanel,
  NameTag,
  PriceTag,
  ProductPanel,
  Separator,
  WorkgroupTag
} from './ProductCardStyle'

const ProductCard = ({
  user,
  translate,
  product,
  fastAddToBag,
  activeLanguage
}) => {
  const [isHover, setIsHover] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const {bagItems} = useSelector((state) => state.bag)

  const bagItem = bagItems.find(
    (item) => item.productId === product.productId
  )

  const handleFastAddClick = async () => {
    setIsLoading(true)
    if (bagItem) {
      await removeProductFromBag(bagItem.bagItemId, dispatch)
    } else {
      await fastAddToBag(product)
    }
    setIsLoading(false)
  }

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const btnStyle = {
    transition: 'all 0.5s ease',
    border: bagItem
      ? `1px solid ${user.configuration?.layoutColors?.contentText}`
      : isHover
      ? `1px solid ${user.configuration?.layoutColors?.contentText}`
      : '1px solid #dedede',
    backgroundColor: bagItem
      ? user.configuration?.layoutColors?.contentText
      : isHover
      ? user.configuration?.layoutColors?.contentText
      : '#dedede'
  }

  return (
    <Col xs={12} lg={12}>
      <ComponentLoadingOverlay isLoading={isLoading}>
        <ProductPanel>
          <CategoryTag
            style={{color: user.configuration?.layoutColors?.contentText}}
          >
            {
              product?.product?.mainCategory?.categoryTranslation?.find(
                (x) => x.languageId === activeLanguage.code
              )?.name
            }
          </CategoryTag>
          <NameTag>
            {
              product?.product?.productTranslation?.find(
                (x) => x.languageId === activeLanguage.code
              )?.name
            }
          </NameTag>
          <WorkgroupTag>{product?.employee?.position?.name}</WorkgroupTag>
          <ImagePanel>
            <ImageBag
              src={BagImage}
              alt='BAG LOGO'
              onClick={handleFastAddClick}
              style={btnStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            <ImageLink to={`/products/${product?.productId}`}>
              <ImageP
                src={GetImage(
                  product?.product?.productImage.find(
                    (x) => x.cover === true
                  )
                )}
                alt='IMG_LOGO'
              />
            </ImageLink>
          </ImagePanel>
          <PriceTag
            style={{color: user.configuration?.layoutColors?.contentText}}
          >
            {`${product?.dotation} ${
              product?.dotation < 2
                ? translate('CART_CREDIT')
                : translate('CART_CREDITS')
            }`}
          </PriceTag>
          <Separator />
          <CreditsTag>
            &nbsp; &nbsp;
            <Translate id='USED_CREDITS' />{' '}
            <CreditsWrapper
              style={{
                color: user.configuration?.layoutColors?.contentText
              }}
            >
              ({product?.dotation - product?.creditsAvailable} /{' '}
              {product?.dotation})
            </CreditsWrapper>
          </CreditsTag>
        </ProductPanel>
      </ComponentLoadingOverlay>
    </Col>
  )
}

ProductCard.propTypes = {
  translate: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fastAddToBag: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(ProductCard))
